import React from 'react'
import { Link } from 'react-router-dom'

const App = () => {

  return (
    <div>
    <h1>Can be used for internal purpose. It has some calls that have many parameters. 
        <br/>
        <br/>
        <br/>
    If something doesnt work, try the normal way of API/Supersite
    <br />
    <br />
    Ignore the UI
    </h1>
      <p>Check if a domain is premium <Link to='/raiders/premium-check'> Click Here</Link> </p>
      <p>Register a Domain <Link to='/raiders/register-domain'> Click Here</Link> </p>
      <p>Create .ES Contact<Link to='/raiders/es-contact'> Click Here</Link> </p>
      <p>Transfer a Domain<Link to='/raiders/transfer-domain'> Click Here</Link> </p>
      <p>GWS Admin account Setup<Link to='/raiders/gws-admin-setup'> Click Here</Link> </p>
      <p>Purchase GWS<Link to='/raiders/gws-purchase'> Click Here</Link> </p>
      <p>Contact Creation<Link to='/raiders/create-contact'> Click Here</Link> </p>
      <p>.CA Contact Creation<Link to='/raiders/ca-contact'> Click Here</Link> </p>
      <p>Purchase SSL<Link to='/raiders/ssl-purchase'> Click Here</Link> </p>
      <p>Check Availability<Link to='/raiders/check-availability'> Click Here</Link> </p>
    </div>
  )
}

export default App