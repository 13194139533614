import React, { useState } from 'react';

const CheckAvailability = () => {
    const [data, setData] = useState({
        resellerid: '',
        key: '',
        domain: '',
        tld:''
    });
    const [generatedUrl, setGeneratedUrl] = useState('');

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('URL copied to clipboard! Paste it now on another browser tab');
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = `https://test.httpapi.com/api/domains/available.json?auth-userid=${data.resellerid}&api-key=${data.key}&domain-name=${data.domain}&tlds=${data.tld}`;
        setGeneratedUrl(url); // Set the generated URL to state
        
        // Open the generated URL in a new tab
    };

    const handleFire = () => {
        if (generatedUrl) {
            window.open(generatedUrl, '_blank');
        } else {
            alert("Please generate the API call before firing it.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
            <h1>Check if a Domain Name is Premium (Registry Premium Domains only)</h1>
            <form onSubmit={handleSubmit}>
                <input type="number" placeholder="Reseller ID" name="resellerid" value={data.resellerid} onChange={handleChange} required/>
                <input type="text" placeholder="API Key" name="key" value={data.key} onChange={handleChange} required/>
                <input type="text" placeholder="Domain Name" name="domain" value={data.domain} onChange={handleChange} required/>
                <input type="text" placeholder="TLD" name="tld" value={data.tld} onChange={handleChange} required/>
                <button type="submit">Generate API Call</button>
                <button onClick={handleFire}>Fire</button>
                
            </form>
            
            {/* Display the generated URL if it exists */}
            {generatedUrl && (
                <div className="result">
                    <h3>API Call generated</h3>
                    <pre>{generatedUrl}</pre>

                    {/* Copy to Clipboard Button */}
                    <button className="copy-button" onClick={() => copyToClipboard(generatedUrl)}>Copy API Call to Clipboard</button>
                </div>
            )}
        </div>
    );
}    

export default CheckAvailability;