import React, { useState } from 'react';

const GWSpurchase = () => {
    const [data, setData] = useState({
        resellerid: '',
        key: '',
        domain:'',
        cid:'',
        planid:'',
        months:'',
        accounts: '',
        invoice:'',
        altemail:'',
        zip:'',
        rpi:''
    });

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('URL copied to clipboard! Paste it now on another browser tab');
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    const[generatedUrl, setGeneratedUrl]= useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
            const url = `https://test.httpapi.com/api/gapps/in/add.json?auth-userid=${data.resellerid}&api-key=${data.key}&customer-id=${data.cid}&domain-name=${data.domain}&months=${data.months}&no-of-accounts=${data.accounts}&invoice-option=${data.invoice}`;
            const url2 = `https://test.httpapi.com/api/gapps/gbl/add.json?auth-userid=${data.resellerid}&api-key=${data.key}&customer-id=${data.cid}&domain-name=${data.domain}&months=${data.months}&no-of-accounts=${data.accounts}&invoice-option=${data.invoice}`;

            let selectedUrl = data.rpi === 'india' ? url : url2;

            if (data.token) {
                selectedUrl+= `&transfer-token=${data.token}`;
            }
            if (data.planid) {
                selectedUrl+= `&plan-id=${data.planid}`;
            }

            setGeneratedUrl(selectedUrl);
    };

    const handleFire = () => {
        if (generatedUrl) {
            window.open(generatedUrl, '_blank');
        } else {
            alert("Please generate the API call before firing it.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
            <div className="container">
                <h1>Purchase/Transfer GWS orders(Should work for both India and Global RPIs unlike our Supersite)</h1>
                <form onSubmit={handleSubmit}>
                    <input type="number" placeholder="Reseller ID" name="resellerid" value={data.resellerid} onChange={handleChange} required/>
                    <input type="text" placeholder="API Key" name="key" value={data.key} onChange={handleChange} required/>
                    <input type="number" placeholder="Customer ID" name="cid" value={data.cid} onChange={handleChange} required/>
                    <input type="text" placeholder="Domain Name" name="domain" value={data.domain} onChange={handleChange} required/>
                    <input type="number" placeholder="Number of months" name="months" value={data.months} onChange={handleChange} required/>
                    <input type="number" placeholder="Number of Accounts" name="accounts" value={data.accounts} onChange={handleChange} required/>
                    <input type="text" placeholder="Transfer Token (Optional)" name="token" value={data.token} onChange={handleChange}/>
                    <input type="number" placeholder="Plan ID (Optional)" name="planid" value={data.planid} onChange={handleChange}/>
                    <select name="rpi" value={data.rpi} onChange={handleChange} required>
                        <option value="">Select RPI</option>
                        <option value="india">India</option>
                        <option value="global">Global</option>
                    </select>
                    <select name="invoice" value={data.invoice} onChange={handleChange} required>
                        <option value="">Select Invoice Type</option>
                        <option value="OnlyAdd">Only Add</option>
                        <option value="KeepInvoice">Keep Invoice</option>
                        <option value="NoInvoice">No Invoice</option>
                    </select>
                    <button type="submit">Generate API Call</button>
                    <button onClick={handleFire}>Fire</button>
                </form>
                {generatedUrl && (
                <div className="result">
                    <h3>API Call generated</h3>
                    <pre>{generatedUrl}</pre>

                    {/* Copy to Clipboard Button */}
                    <button className="copy-button" onClick={() => copyToClipboard(generatedUrl)}>Copy API Call to Clipboard</button>
                </div>
            )}
            </div>
    );
}

export default GWSpurchase;