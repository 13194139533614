import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div>
      <h1>Go to list <Link to='/raiders'> Click Here</Link> </h1>
    </div>
  )
}

export default Home