import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PremiunCheck from './raiders/PremiunCheck';
import DomainRegister from './raiders/DomainRegister';
import EsContact from './raiders/EsContact';
import DomainTransfer from './raiders/DomainTransfer';
import GWSadminAccount from './raiders/GWSadminAccount';
import GWSpurchase from './raiders/GWSpurchase';
import CreateContact from './raiders/CreateContact';
import Home from './Home'
import CaContact from './raiders/CaContact';
import SslPurchase from './raiders/SslPurchase';
import CheckAvailability from './raiders/CheckAvailability';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/raiders" element={<App />} />
            <Route path="/raiders/premium-check" element={<PremiunCheck />} />
            <Route path="/raiders/register-domain" element={<DomainRegister />} />
            <Route path="/raiders/es-contact" element={<EsContact />} />
            <Route path="/raiders/transfer-domain" element={<DomainTransfer />} />
            <Route path="/raiders/gws-admin-setup" element={<GWSadminAccount />} />
            <Route path="/raiders/gws-purchase" element={<GWSpurchase />} />
            <Route path="/raiders/create-contact" element={<CreateContact />} />
            <Route path="/raiders/ca-contact" element={<CaContact />} />
            <Route path="/raiders/ssl-purchase" element={<SslPurchase />} />
            <Route path="/raiders/check-availability" element={<CheckAvailability />} />
        </Routes>
    </BrowserRouter>
);