import React, { useState } from 'react';

const CreateContact = () => {
    const [data, setData] = useState({
        resellerid: '',
        key: '',
        contactname: '',
        company: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        phonecc: '',
        phone: '',
        cid: '',
        type: '',
        extra: '',
    });

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('URL copied to clipboard!');
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    const [generatedUrl, setGeneratedUrl] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        let url = `https://test.httpapi.com/api/contacts/add.json?auth-userid=${data.resellerid}&api-key=${data.key}&name=${data.contactname}&company=${data.company}&email=${data.email}&address-line-1=${data.address}&city=${data.city}&state=${data.state}&country=${data.country}&zipcode=${data.zip}&phone-cc=${data.phonecc}&phone=${data.phone}&customer-id=${data.cid}&type=${data.type}`;

        if (data.extra) {
            url += `&${data.extra}`;
        }

        setGeneratedUrl(url);
    };

    const handleFire = () => {
        if (generatedUrl) {
            window.open(generatedUrl, '_blank');
        } else {
            alert("Please generate the API call before firing it.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
<style>{`
                /* Parent Container Styling */
                .main-container {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 2rem;
                    padding: 2rem;
                    background: linear-gradient(135deg, #e0f7fa, #f4f4f9);
                    height: 100vh;
                }

                /* Left Container Styling */
                .container {
                    width: 50%;
                    padding: 1.5rem;
                    background-color: #ffffff;
                    border-radius: 12px;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s;
                }

                .container:hover {
                    transform: translateY(-3px);
                }

                /* Right Params Box Styling */
                .params {
                    width: 40%;
                    padding: 1.5rem;
                    background-color: #e0f7fa;
                    border-radius: 12px;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s;
                }

                .params:hover {
                    transform: translateY(-3px);
                }

                .params h1 {
                    color: #00796b;
                    font-size: 1.5rem;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 1em;
                }

                .params h3 {
                    color: #00796b;
                    font-size: 1.2rem;
                    margin-top: 1em;
                    font-weight: 500;
                }

                .params p, .params a {
                    font-size: 1rem;
                    color: #004d40;
                    line-height: 1.5;
                    margin: 0.5rem 0;
                    text-align: justify;
                    display: block;
                }

                .params a {
                    color: #007acc;
                    text-decoration: none;
                    font-weight: 500;
                    transition: color 0.3s;
                }

                .params a:hover {
                    color: #005f99;
                }
            `}</style>
            
            <div className="main-container">
                <div className="container">
                    <h1>Create Contact IDs</h1>
                    <form onSubmit={handleSubmit}>
                        <input type="number" placeholder="Reseller ID" name="resellerid" value={data.resellerid} onChange={handleChange} required />
                        <input type="text" placeholder="API Key" name="key" value={data.key} onChange={handleChange} required />
                        <input type="number" placeholder="Customer ID" name="cid" value={data.cid} onChange={handleChange} required />
                        <input type="text" placeholder="Name" name="contactname" value={data.contactname} onChange={handleChange} required />
                        <input type="text" placeholder="Company" name="company" value={data.company} onChange={handleChange} required />
                        <input type="email" placeholder="Email" name="email" value={data.email} onChange={handleChange} required />
                        <input type="text" placeholder="Address" name="address" value={data.address} onChange={handleChange} required />
                        <input type="text" placeholder="City" name="city" value={data.city} onChange={handleChange} required />
                        <input type="text" placeholder="State" name="state" value={data.state} onChange={handleChange} required />
                        <input type="text" placeholder="Country (Two Letter Code only)" name="country" value={data.country}
                            onChange={(e) => {
                                const value = e.target.value.toUpperCase(); // Convert to uppercase
                                if (value.length <= 2) { // Limit to 2 characters
                                    handleChange({ target: { name: e.target.name, value } });
                                }
                            }}
                            pattern="[A-Z]{2}"
                            title="Please enter a two-letter country code in uppercase (e.g., US)"
                            required
                        />
                        <input type="text" placeholder="Zip Code" name="zip" value={data.zip} onChange={handleChange} required />
                        <input type="number" placeholder="Phone CC" name="phonecc" value={data.phonecc} onChange={handleChange} required />
                        <input type="number" placeholder="Phone Number" name="phone" value={data.phone} onChange={handleChange} required />
                        <select name="type" value={data.type} onChange={handleChange} required>
                            <option value="">Select Contact Type</option>
                            <option value="Contact">General Contact</option>
                            <option value="BrContact">.BR Contact (Requires Additional parameters)</option>
                            <option value="BrOrgContact">.BR Organization Contact</option>
                            <option value="ClContact">.CL Contact</option>
                            <option value="CnContact">.CN Contact</option>
                            <option value="CoContact">.CO Contact</option>
                            <option value="DeContact">.DE Contact</option>
                            <option value="EuContact">.EU Contact(May Require Additional parameters)</option>
                            <option value="FrContact">.FR Contact</option>
                            <option value="MxContact">.MX Contact</option>
                            <option value="NlContact">.NL Contact(Requires Additional parameters)</option>
                            <option value="NycContact">.NYC Contact</option>
                            <option value="RuContact">.RU Contact(Requires Additional parameters)</option>
                            <option value="UkContact">.UK Contact</option>
                            <option value="UkServiceContact">.UK Service Contact</option>
                        </select>
                        <br />
                        <input type="text" placeholder="Additional Parameters(Optional)" name="extra" value={data.extra} onChange={handleChange} />
                        <br />
                        <button type="submit">Generate API Call</button>
                        <button type="button" onClick={handleFire}>Fire</button>
                    </form>
                </div>

                <div className="params">
                    <h1>Additional Parameters info</h1>
                        <h3>For .CN</h3>
                        <p>attr-name1=organisationVerificationId&attr-value1=(Mention any ID associated with the organization)</p>
                        <br />
                        <h3>For .BR</h3>           
                        <a href='https://manage.resellerclub.com/kb/answer/795#:~:text=value1%3D%7BOrganisation%20ID%7D-,NOTE,-Organisation%20ID%3A' target="_blank" rel="noopener noreferrer">KB to Refer</a>
                        <p>attr-name1=organisationId&attr-value1=(Organisation ID)</p>
                        <br />
                        <h3>For .NL</h3>
                        <p>attr-name1=legalForm&attr-value1=(PERSOON | ANDERS)</p>
                        <br />
                        <h3>For .EU (Not Mandatory)</h3>
                        <a href='https://manage.resellerclub.com/kb/answer/790#:~:text=attr%2Dvalue3%3D1-,You,-need%20to%20pass' target="_blank" rel="noopener noreferrer">KB to Refer</a>
                        <p>attr-name1=countryOfCitizenship&attr-value1=(Two-lettered country code)</p>
                        <br />
                        <h3>For .RU</h3>
                        <a href='https://manage.resellerclub.com/kb/answer/795#:~:text=To%20associate%20the%20Contact%20with%20a%20.RU%20domain%20name%2C%20the%20input%20can%20be%3A' target="_blank" rel="noopener noreferrer">KB to Refer</a>
                </div>

                {generatedUrl && (
                    <div className="result">
                        <h3>API Call generated</h3>
                        <pre>{generatedUrl}</pre>
                        <button className="copy-button" onClick={() => copyToClipboard(generatedUrl)}>Copy API Call to Clipboard</button>
                    </div>
                )}
            </div>
        </>
    );
};

export default CreateContact;
