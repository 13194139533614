import React, { useState } from 'react';

const GWSadminAccount = () => {
    const [data, setData] = useState({
        resellerid: '',
        key: '',
        orderid:'',
        fname:'',
        lname:'',
        fullname:'',
        company: '',
        email:'',
        altemail:'',
        zip:'',
        token:''
    });

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('URL copied to clipboard! Paste it now on another browser tab');
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    const[generatedUrl, setGeneratedUrl]= useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
            const url = `https://test.httpapi.com/api/gapps/in/admin/add.json?auth-userid=${data.resellerid}&api-key=${data.key}&order-id=${data.orderid}&email-address=${data.email}&password=password&first-name=${data.fname}&last-name=${data.lname}&name=${data.fullname}&alternate-email-address=${data.altemail}&company=${data.company}&zip=${data.zip}`;
            const url2 = `https://test.httpapi.com/api/gapps/gbl/admin/add.json?auth-userid=${data.resellerid}&api-key=${data.key}&order-id=${data.orderid}&email-address=${data.email}&password=password&first-name=${data.fname}&last-name=${data.lname}&name=${data.fullname}&alternate-email-address=${data.altemail}&company=${data.company}&zip=${data.zip}`;

            let selectedUrl = data.rpi === 'india' ? url : url2;

            if(data.token){
                selectedUrl+=`&transfer-token=${data.token}`;
            }

            setGeneratedUrl(selectedUrl);
    };

    const handleFire = () => {
        if (generatedUrl) {
            window.open(generatedUrl, '_blank');
        } else {
            alert("Please generate the API call before firing it.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div class='container'>
            <h1>GWS Admin account Setup</h1>
            <form onSubmit={handleSubmit}>
                <input type="number" placeholder="Reseller ID" name="resellerid" value={data.resellerid} onChange={handleChange} required/>
                <input type="text" placeholder="API Key" name="key" value={data.key} onChange={handleChange} required/>
                <input type="number" placeholder="Order ID" name="orderid" value={data.orderid} onChange={handleChange} required/>
                <br></br><br></br>
                <input type="text" placeholder="First Name" name="fname" value={data.fname} onChange={handleChange} required/>
                <input type="text" placeholder="Last Name" name="lname" value={data.lname} onChange={handleChange} required/>
                <input type="text" placeholder="Full Name" name="fullname" value={data.fullname} onChange={handleChange} required/>
                <input type="text" placeholder="Company Name" name="company" value={data.company} onChange={handleChange} required/>
                <br></br> <br></br>
                <input type="email" placeholder="Email Address" name="email" value={data.email} onChange={handleChange} required/>
                <input type="email" placeholder="Alternate Email Address" name="altemail" value={data.altemail} onChange={handleChange} required/>
                <input type="string" placeholder="Zip Code" name="zip" value={data.zip} onChange={handleChange} required/>
                <br /><br />
                <input type="string" placeholder="Transfer Token(Optional)" name="token" value={data.token} onChange={handleChange}/>
                <select name="rpi" value={data.rpi} onChange={handleChange} required>
                    <option value="">Select RPI</option>
                    <option value="india">India</option>
                    <option value="global">Global</option>
                </select>
                <br></br><br></br>
                <button type="submit">Generate API Call</button>
                <button onClick={handleFire}>Fire</button>
            </form>
            {generatedUrl && (
                <div className="result">
                    <h3>API Call generated</h3>
                    <pre>{generatedUrl}</pre>

                    {/* Copy to Clipboard Button */}
                    <button className="copy-button" onClick={() => copyToClipboard(generatedUrl)}>Copy API Call to Clipboard</button>
                </div>
            )}
        </div>   
    );
}    

export default GWSadminAccount;