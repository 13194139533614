import React, { useState } from 'react';
import './index.css'

const CaContact = () => {
    const [data, setData] = useState({
        resellerid: '',
        key: '',
        contactname: '',
        company: '',
        email:'',
        address:'',
        city:'',
        state:'',
        country:'',
        zip:'',
        phonecc:'',
        phone:'',
        cid:'',
        extra1:''
    });

    const[generatedUrl, setGeneratedUrl]= useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
            const url = `https://test.httpapi.com/api/contacts/add.json?auth-userid=${data.resellerid}&api-key=${data.key}&name=${data.contactname}&company=${data.company}&email=${data.email}&address-line-1=${data.address}&city=${data.city}&state=${data.state}&country=${data.country}&zipcode=${data.zip}&phone-cc=${data.phonecc}&phone=${data.phone}&customer-id=${data.cid}&type=CaContact&attr-name1=CPR&attr-value1=${data.extra1}&attr-name2=AgreementVersion&attr-value2=2.2&attr-name3=AgreementValue&attr-value3=y`;
            setGeneratedUrl(url);
    };

    const handleFire = () => {
        if (generatedUrl) {
            window.open(generatedUrl, '_blank');
        } else {
            alert("Please generate the API call before firing it.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('URL copied to clipboard! Paste it now on another browser tab');
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    return (
        <div class='container'>
            <h1>.CA Contact Creation</h1>
            <form onSubmit={handleSubmit}>
                <input type="number" placeholder="Reseller ID" name="resellerid" value={data.resellerid} onChange={handleChange} required/>
                <input type="text" placeholder="API Key" name="key" value={data.key} onChange={handleChange} required/>
                <input type="number" placeholder="Customer ID" name="cid" value={data.cid} onChange={handleChange} required/>
                <br></br><br></br>
                <input type="text" placeholder="Name" name="contactname" value={data.contactname} onChange={handleChange} required/>
                <input type="text" placeholder="Company" name="company" value={data.company} onChange={handleChange} required/>
                <input type="email" placeholder="Email" name="email" value={data.email} onChange={handleChange} required/>
                <br></br> <br></br>
                <input type="string" placeholder="Address" name="address" value={data.address} onChange={handleChange} required/>
                <input type="string" placeholder="City" name="city" value={data.city} onChange={handleChange} required/>
                <input type="string" placeholder="State" name="state" value={data.state} onChange={handleChange} required/>
                <input type="string" placeholder="Country(Two Letter Code only)" name="country" value={data.country}
                onChange={(e) => {
                            const value = e.target.value.toUpperCase(); // Convert to uppercase
                            if (value.length <= 2) { // Limit to 2 characters
                                handleChange({ target: { name: e.target.name, value } });
                            }
                        }}
                    pattern="[A-Z]{2}" // Ensures only two uppercase letters are allowed
                    title="Please enter a two-letter country code in uppercase (e.g., US)"
                    required/>

                <input type="string" placeholder="Zip Code" name="zip" value={data.zip} onChange={handleChange} required/>
                <br></br><br></br>
                <input type="number" placeholder="Phone CC" name="phonecc" value={data.phonecc} onChange={handleChange} required/>
                <input type="number" placeholder="Phone Number" name="phone" value={data.phone} onChange={handleChange} required/>
                <br /><br />
                <p><a href='https://manage.resellerclub.com/kb/answer/795#:~:text=code%20mentioned%20below%7D-,CPR,-Description' target="_blank" rel="noopener noreferrer">Refer to the KB for adding these details</a></p>
                <input type="text" placeholder="CPR Value" name="extra1" value={data.extra1} onChange={(e) => {
                            const value = e.target.value.toUpperCase(); // Convert to uppercase
                            if (value.length <= 3) { // Limit to 2 characters
                                handleChange({ target: { name: e.target.name, value } });
                            }
                        }}
                    pattern="[A-Z]{3}" // Ensures only two uppercase letters are allowed
                    title="Please check the KB"
                    required/>
                <br></br><br></br>
                <button type="submit">Generate API call</button>
                <button onClick={handleFire}>Fire</button>
            </form>
            {generatedUrl && (
                <div className="result">
                    <h3>API Call generated</h3>
                    <pre>{generatedUrl}</pre>

                    {/* Copy to Clipboard Button */}
                    <button className="copy-button" onClick={() => copyToClipboard(generatedUrl)}>Copy API Call to Clipboard</button>
                </div>
            )}
        </div>
    );
}    

export default CaContact;