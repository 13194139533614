import React, { useState } from 'react';

const DomainTransfer = () => {
    const [data, setData] = useState({
        resellerid: '',
        key: '',
        domain: '',
        cid: '',
        years: '',
        authcode: '',
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        rcoid: '',
        acoid: '',
        tcoid: '',
        bcoid: '',
        invoice: '',
        add:''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('URL copied to clipboard!');
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    const[generatedUrl, setGeneratedUrl]= useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
            let url = `https://test.httpapi.com/api/domains/transfer.xml?auth-userid=${data.resellerid}&api-key=${data.key}&domain-name=${data.domain}&ns=${data.ns1}&ns=${data.ns2}&customer-id=${data.cid}&reg-contact-id=${data.rcoid}&admin-contact-id=${data.acoid}&tech-contact-id=${data.tcoid}&billing-contact-id=${data.bcoid}&invoice-option=${data.invoice}`;

            if (data.ns3) {
                url += `&ns=${data.ns3}`;
            }

            if (data.ns4) {
                url += `&ns=${data.ns4}`;
            }

            if(data.add){
                url += `&${data.add}`;
            }
            if(data.authcode){
                url += `&auth-code=${data.authcode}`;
            }

            setGeneratedUrl(url);
    };

    const handleFire = () => {
        if (generatedUrl) {
            window.open(generatedUrl, '_blank');
        } else {
            alert("Please generate the API call before firing it.");
        }
    };

    return (
        <div className='container'>
            <h1>Domain Transfer</h1>
            <form onSubmit={handleSubmit}>
            <input type="number" placeholder="Reseller ID" name="resellerid" value={data.resellerid} onChange={handleChange} required/>
        <input type="text" placeholder="API Key" name="key" value={data.key} onChange={handleChange} required/>
        <br></br><br></br>
        <input type="text" placeholder="Domain Name" name="domain" value={data.domain} onChange={handleChange} required/>
        <input type="string" placeholder="Auth Code" name="authcode" value={data.authcode} onChange={handleChange}/>
        <input type="number" placeholder="Customer ID" name="cid" value={data.cid} onChange={handleChange} required/>
        <br />
        <br />
        <input type="text" placeholder="Nameserver 1" name="ns1" value={data.ns1} onChange={handleChange} required/>
        <input type="text" placeholder="Nameserver 2" name="ns2" value={data.ns2} onChange={handleChange} required/>
        <input type="text" placeholder="Nameserver 3(Optional)" name="ns3" value={data.ns3} onChange={handleChange} />
        <input type="text" placeholder="Nameserver 4(Optional)" name="ns4" value={data.ns4} onChange={handleChange} />
        <br />
        <br />
        <input type="number" placeholder="Registrant Contact ID" name="rcoid" value={data.rcoid} onChange={handleChange} required/>
        <input type="number" placeholder="Administrative Contact ID" name="acoid" value={data.acoid} onChange={handleChange} required/>
        <input type="number" placeholder="Billing Contact ID" name="bcoid" value={data.bcoid} onChange={handleChange} required/>
        <input type="number" placeholder="Technical Contact ID" name="tcoid" value={data.tcoid} onChange={handleChange} required/>
        <br />
        <br />
        <select name="invoice" value={data.invoice} onChange={handleChange} required>
            <option value="">Select Invoice Type</option>
            <option value="OnlyAdd">Only Add</option>
            <option value="KeepInvoice">Keep Invoice</option>
            <option value="NoInvoice">No Invoice</option>
        </select>
        <br />
        <p>If there are any other transfers which required additional attributes, add the attributes like we usually do in API calls. These will be automatically indented to the entire API call</p>
        <p>Example: Just add attr-name1=tnc&attr-value1=Y </p>
        <a href='https://manage.resellerclub.com/kb/answer/758#:~:text=Premium%20DNS%20Service-,HTTP%20Method,-POST' target="_blank" rel="noopener noreferrer">Click here to refer</a>
        <br />
        <select name='add' value={data.add} onChange={handleChange}>
            <option value="">Preset (Optional)</option>
            <option value="attr-name1=premium&attr-value1=true">Registry Premium Domains</option>
            <option value="attr-name1=type&attr-value1=premiumft&attr-name2=premiumprice&attr-value2=0">Aftermarket Premium Domains</option>
        </select>
        <input type="text" placeholder="Use this if the desried option is not available in the above dropdown/more parameters are to be added" name="add" value={data.add} onChange={handleChange} />
        <br />
        <br />
        
                <button type="submit">Generate API Call</button>
                <button onClick={handleFire}>Fire</button>
            </form>
            {generatedUrl && (
                <div className="result">
                    <h3>API Call generated</h3>
                    <pre>{generatedUrl}</pre>

                    {/* Copy to Clipboard Button */}
                    <button className="copy-button" onClick={() => copyToClipboard(generatedUrl)}>Copy API Call to Clipboard</button>
                </div>
            )}
        </div>
    );
};

export default DomainTransfer;