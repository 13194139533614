import React, { useState } from 'react';

const EsContact = () => {
    const [data, setData] = useState({
        resellerid: '',
        key: '',
        contactname: '',
        company: '',
        email:'',
        address:'',
        city:'',
        state:'',
        country:'',
        zip:'',
        phonecc:'',
        phone:'',
        cid:'',
        extra1:'',
        extra2:'',
        extra3:'',
    });

    const[generatedUrl, setGeneratedUrl]= useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
            const url = `https://test.httpapi.com/api/contacts/add.json?auth-userid=${data.resellerid}&api-key=${data.key}&name=${data.contactname}&company=${data.company}&email=${data.email}&address-line-1=${data.address}&city=${data.city}&state=${data.state}&country=${data.country}&zipcode=${data.zip}&phone-cc=${data.phonecc}&phone=${data.phone}&customer-id=${data.cid}&type=EsContact&attr-name1=es_form_juridica&attr-value1=${data.extra1}&attr-name2=es_tipo_identificacion&attr-value2=${data.extra2}&attr-name3=es_identificacion&attr-value3=${data.extra3}`;
            setGeneratedUrl(url);
    };

    const handleFire = () => {
        if (generatedUrl) {
            window.open(generatedUrl, '_blank');
        } else {
            alert("Please generate the API call before firing it.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('URL copied to clipboard! Paste it now on another browser tab');
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    return (
        <div class='container'>
            <h1>.ES Contact Creation</h1>
            <form onSubmit={handleSubmit}>
                <input type="number" placeholder="Reseller ID" name="resellerid" value={data.resellerid} onChange={handleChange} required/>
                <input type="text" placeholder="API Key" name="key" value={data.key} onChange={handleChange} required/>
                <input type="number" placeholder="Customer ID" name="cid" value={data.cid} onChange={handleChange} required/>
                <br></br><br></br>
                <input type="text" placeholder="Name" name="contactname" value={data.contactname} onChange={handleChange} required/>
                <input type="text" placeholder="Company" name="company" value={data.company} onChange={handleChange} required/>
                <input type="email" placeholder="Email" name="email" value={data.email} onChange={handleChange} required/>
                <br></br> <br></br>
                <input type="string" placeholder="Address" name="address" value={data.address} onChange={handleChange} required/>
                <input type="string" placeholder="City" name="city" value={data.city} onChange={handleChange} required/>
                <input type="string" placeholder="State" name="state" value={data.state} onChange={handleChange} required/>
                <input type="string" placeholder="Country(Two Letter Code only)" name="country" value={data.country} onChange={handleChange} required/>
                <input type="string" placeholder="Zip Code" name="zip" value={data.zip} onChange={handleChange} required/>
                <br></br><br></br>
                <input type="number" placeholder="Phone CC" name="phonecc" value={data.phonecc} onChange={handleChange} required/>
                <input type="number" placeholder="Phone Number" name="phone" value={data.phone} onChange={handleChange} required/>
                <br /><br />
                <p><a href='https://manage.resellerclub.com/kb/answer/790#:~:text=persons/individuals%20only.-,Value,-Legal%20Entity%20Type' target="_blank" rel="noopener noreferrer">Refer to the KB for adding these details</a></p>
                <input type="number" placeholder="Value of Legal Entity type" name="extra1" value={data.extra1} onChange={handleChange} required/>
                <input type="number" placeholder="Type of ID" name="extra2" value={data.extra2} onChange={handleChange} required/>
                <input type="string" placeholder="ID Number" name="extra3" value={data.extra3} onChange={handleChange} required/>
                <br></br><br></br>
                <button type="submit">Generate API call</button>
                <button onClick={handleFire}>Fire</button>
            </form>
            {generatedUrl && (
                <div className="result">
                    <h3>API Call generated</h3>
                    <pre>{generatedUrl}</pre>

                    {/* Copy to Clipboard Button */}
                    <button className="copy-button" onClick={() => copyToClipboard(generatedUrl)}>Copy API Call to Clipboard</button>
                </div>
            )}
        </div>
    );
}    

export default EsContact;